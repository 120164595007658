import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { onValue, ref, update } from "firebase/database";

const CareerForm = () => {
  const location = useLocation();

  const [careerData, setCareerData] = useState(location.state.item);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [toast, setToast] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  let resume;

  const setResume = (event) => {
    console.log("file :- ", event);
    let reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onload = function() {
      resume = reader.result;
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
    // getBase64(idCard, (result) => {
    //   idCardBase64 = result;
    // });
  };

  const setCareerInquiry = () => {
    let careerDataFromDb;
    console.log("CareerData :- ", careerData);

    if (!name || !email || !phone || !subject || !message) {
      alert("Please enter all the details. ");
      return;
    }
    if (careerData && careerData.id) {
      let query = ref(db, "career/" + careerData.id);
      onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          careerDataFromDb = data;
        }
      });

      console.log("careerDataFromDb :- ", careerDataFromDb);

      let inquiry = {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
        resume: resume,
      };

      console.log("Inquiry :- ", inquiry);
      if (careerDataFromDb.inquiry && careerDataFromDb.inquiry.length) {
        careerDataFromDb.inquiry.push(inquiry);
      } else {
        careerDataFromDb.inquiry = [inquiry];
      }

      console.log("careerDataFromDb :- ", careerDataFromDb);

      update(ref(db, "career/" + careerDataFromDb.id), careerDataFromDb)
        .then(() => {
          setToast("Applied for job successfully");
          console.log("data added");
          setName("");
          setEmail("");
          setPhone("");
          setSubject("");
          setMessage("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    console.log(careerData);
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title = "Career - M&M Installing";
  }, []);
  return (
    <>
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1 className="breadcrumb__title">Career</h1>
                <p>Fill out this form</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact__area">
        <div className="container line">
          <span className="line-3"></span>
          <span className="line-4"></span>
          <span className="line-5"> </span>
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="contact__content">
                <h2 className="contact__title">Get in touch</h2>
                <p>
                  Get in touch to discuss your employee wellbeing needs today.
                  Please give us a call, drop us an email.
                </p>
                <div
                  className="contact__form wow wcfadeUp"
                  data-wow-delay="0.75s"
                >
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your name"
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        placeholder="Your email"
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                      <label htmlFor="phone">Phone </label>
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="tel"
                        id="phone"
                        placeholder="Your phone"
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                      <label htmlFor="subject">Subject</label>
                      <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        id="subject"
                        placeholder="Your subject"
                      />
                    </div>
                    <div className="col-xxl-12">
                      <label htmlFor="message">Message</label>
                      <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        name="Message"
                        id="message"
                        placeholder="Type your message..."
                      ></textarea>
                    </div>

                    <div className="col-xxl-12">
                      <label htmlFor="resume">Upload Resume</label>
                      <input
                        type="file"
                        onChange={(e) => setResume(e.target.files[0])}
                        name="resume"
                        id="resume"
                        placeholder="Select your resume"
                      />
                    </div>
                    <div id="message" className="mt-2 mb-2 message">
                      {toast}
                    </div>
                    <div className="col-xxl-12">
                      <button
                        className="submit wc-btn-primary btn-hover"
                        onClick={setCareerInquiry}
                      >
                        <span></span> Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerForm;
